<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <!--<a-col :md="8" :sm="24">-->
              <!--<a-form-item label="报名用户id" prop="userId">-->
                <!--<a-input v-model="queryParam.userId" placeholder="请输入报名用户id" allow-clear/>-->
              <!--</a-form-item>-->
            <!--</a-col>-->
            <a-col :md="8" :sm="24">
              <a-form-item label="身份证号" prop="idCard">
                <a-input v-model="queryParam.idCard" placeholder="请输入身份证号" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="学员姓名" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入学员姓名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="10" :sm="12">
              <a-form-item label="下单时间">
                <j-date v-model="queryParam.createBeginTime"  date-format="YYYY-MM-DD 00:00:00"
                        style="width:45%" placeholder="请选择开始时间"></j-date>
                <span style="width: 10px;">~</span>
                <j-date v-model="queryParam.createEndTime"  date-format="YYYY-MM-DD 23:59:59" style="width:45%"
                        placeholder="请选择结束时间"></j-date>
              </a-form-item>
            </a-col>

            <!--<template v-if="advanced">-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="性别" prop="sex">-->
                  <!--<a-select placeholder="请选择性别" v-model="queryParam.sex" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.sys_user_sex" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="项目id" prop="trainingId">-->
                  <!--<a-input v-model="queryParam.trainingId" placeholder="请输入项目id" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="会员类型" prop="vipType">-->
                  <!--<a-select placeholder="请选择会员类型" v-model="queryParam.vipType" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.member_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="班别1普通 2自培 3钻石 4铂金" prop="classType">-->
                  <!--<a-select placeholder="请选择班别1普通 2自培 3钻石 4铂金" v-model="queryParam.classType" style="width: 100%" allow-clear>-->
                    <!--<a-select-option v-for="(d, index) in dict.type.class_type" :key="index" :value="d.value">{{ d.label }}</a-select-option>-->
                  <!--</a-select>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="1本地 0外地" prop="nonlocal">-->
                  <!--<a-input v-model="queryParam.nonlocal" placeholder="请输入1本地 0外地" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <a-col :md="8" :sm="24">
                <a-form-item label="联系电话" prop="mobile">
                  <a-input v-model="queryParam.mobile" placeholder="请输入联系电话" allow-clear/>
                </a-form-item>
              </a-col>

            <a-col :md="8" :sm="24">
              <a-form-item label="车型查询" prop="carType">
                <a-select :allowClear=true placeholder="请选择车型" v-model="queryParam.carType" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in dict.type.training_course" :key="index" :value="d.label">{{ d.label }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="校区查询" prop="carType">
                <a-select :allowClear=true placeholder="请选择校区" v-model="queryParam.campusId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in campusList" :key="index" :value="d.id">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="员工id" prop="empId">-->
                  <!--<a-input v-model="queryParam.empId" placeholder="请输入员工id" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="区域id" prop="areaId">-->
                  <!--<a-input v-model="queryParam.areaId" placeholder="请输入区域id" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="校区id" prop="campusId">-->
                  <!--<a-input v-model="queryParam.campusId" placeholder="请输入校区id" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="是否开发票1是 0否" prop="isInvoice">-->
                  <!--<a-input v-model="queryParam.isInvoice" placeholder="请输入是否开发票1是 0否" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
              <!--<a-col :md="8" :sm="24">-->
                <!--<a-form-item label="缴纳金额" prop="payMoney">-->
                  <!--<a-input v-model="queryParam.payMoney" placeholder="请输入缴纳金额" allow-clear/>-->
                <!--</a-form-item>-->
              <!--</a-col>-->
            <!--</template>-->
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>

                <!--<a @click="toggleAdvanced" style="margin-left: 8px">-->
                  <!--{{ advanced ? '收起' : '展开' }}-->
                  <!--<a-icon :type="advanced ? 'up' : 'down'"/>-->
                <!--</a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--<a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['driving-school:order:add']">-->
          <!--<a-icon type="plus" />新增-->
        <!--</a-button>-->

<!--        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['driving-school:order:edit']">-->
<!--          <a-icon type="edit" />修改-->
<!--        </a-button>-->
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['order:pay:delete']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['order:pay:look:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button type="primary" @click="handleStudentExport" v-hasPermi="['order:pay:look:export']">
          <a-icon type="download" />导出区域统计
        </a-button>
        <a-button type="primary" @click="handleStudentExportByEmp" v-hasPermi="['order:pay:look:export']">
          <a-icon type="download" />导出员工校区统计
        </a-button>

        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <div><h3>统计缴费金额: {{sumMoney}}</h3></div>

      <!-- 赠送福利金额 -->
      <give-form
        ref="giveForm"
        @ok="getList"
      />

      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        :sexOptions="dict.type.sys_user_sex"
        :vipTypeOptions="dict.type.member_type"
        :classTypeOptions="dict.type.class_type"
        :isInvoiceOptions="dict.type.is_invoice"
        :payStateOptions="dict.type.pay_state"
        :trainingCourseOptions="dict.type.training_course"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="sex" slot-scope="text, record">
          <dict-tag :options="dict.type['sys_user_sex']" :value="record.sex"/>
        </span>
        <span slot="payState" slot-scope="text, record">
          <dict-tag :options="dict.type['pay_state']" :value="record.payState"/>
        </span>
        <span slot="vipType" slot-scope="text, record">
          <dict-tag :options="dict.type['member_type']" :value="record.vipType"/>
        </span>
        <span slot="classType" slot-scope="text, record">
          <dict-tag :options="dict.type['class_type']" :value="record.classType"/>
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
           <a-divider type="vertical"  v-hasPermi="['order:pay:give']"/>
          <a @click="$refs.giveForm.handleUpdate(record)"  v-hasPermi="['order:pay:give']">
            <a-icon type="param" />赠送福利金额
          </a>

          <a-divider type="vertical" v-hasPermi="['order:pay:look']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['order:pay:look']">
            <a-icon type="edit" />查看
          </a>
          <a-divider type="vertical" v-hasPermi="['order:pay:delete']" />
          <a @click="handleDelete(record)" v-hasPermi="['order:pay:delete']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageOrder,listOrder, delOrder } from '@/api/driving-school/order'
import { listCampus } from '@/api/driving-school/campus'
import CreateForm from './modules/CreateForm'
import GiveForm from './modules/GiveForm'
import { tableMixin } from '@/store/table-mixin'
import JDate from '@/components/date/JDate'

export default {
  name: 'Order',
  components: {
    GiveForm,
    CreateForm,
    JDate
  },
  mixins: [tableMixin],
  dicts: ['sys_user_sex', 'member_type', 'class_type', 'is_invoice','pay_state','training_course'],
  data () {
    return {
      campusList:[],
      sumMoney:0,
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        createBeginTime: null,
        createEndTime: null,
        id: null,
        userId: null,
        name: null,
        idCard: null,
        sex: null,
        trainingId: null,
        vipType: null,
        classType: null,
        nonlocal: null,
        mobile: null,
        empId: null,
        areaId: null,
        campusId: null,
        isInvoice: null,
        payMoney: null,
        leaveWords: null,
        payState: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '身份证号',
          dataIndex: 'idCard',
          ellipsis: true,
          align: 'center'
        },

        {
          title: '学员姓名',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center',
          width: 80
        },
        // {
        //   title: '身份证号',
        //   dataIndex: 'idCard',
        //   ellipsis: true,
        //   align: 'center'
        // },
        // {
        //   title: '性别',
        //   dataIndex: 'sex',
        //   scopedSlots: { customRender: 'sex' },
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '报名车型',
          dataIndex: 'carType',
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '会员类型',
          dataIndex: 'vipType',
          scopedSlots: { customRender: 'vipType' },
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '班别',
          dataIndex: 'classType',
          scopedSlots: { customRender: 'classType' },
          ellipsis: true,
          align: 'center',
          width: 80
        },
        {
          title: '地区',
          dataIndex: 'nonlocal',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value==1) {
              return "本地";
            } else {
              return "外地";
            }
          },
          width: 80
        },
        {
          title: '联系电话',
          dataIndex: 'mobile',
          ellipsis: true,
          align: 'center',
          width: 100
        },
        {
          title: '员工',
          dataIndex: 'empName',
          ellipsis: true,
          align: 'center',
          width: 80
        },
        // {
        //   title: '区域id',
        //   dataIndex: 'areaId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '校区',
          dataIndex: 'campusName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '缴费',
          dataIndex: 'payState',
          scopedSlots: { customRender: 'payState' },
          ellipsis: true,
          align: 'center',
          width: 70
        },
        {
          title: '邀请人姓名',
          dataIndex: 'referrerName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '留言',
        //   dataIndex: 'leaveWords',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '报名时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开发票',
          dataIndex: 'isInvoice',
          ellipsis: true,
          align: 'center',
          customRender: function (value) {
            if (value==1) {
              return "是";
            } else {
              return "否";
            }
          },
          width: 80
        },
        {
          title: '已赠送福利金额',
          dataIndex: 'welfareSum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getCampusList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询报名列表 */
    getList () {
      this.loading = true
      this.queryParam.payState=1
     pageOrder(this.queryParam).then(response => {
        this.list = response.data.records
       if (this.list.length>0){
         this.sumMoney=this.list[0].sumMoney
       }
        this.total = response.data.total
        this.loading = false
      })
    },
    getCampusList(){
      listCampus(null).then(response=>{
        console.log(8888,response)
        this.campusList = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        userId: undefined,
        name: undefined,
        idCard: undefined,
        sex: undefined,
        trainingId: undefined,
        vipType: undefined,
        classType: undefined,
        nonlocal: undefined,
        mobile: undefined,
        empId: undefined,
        areaId: undefined,
        campusId: undefined,
        isInvoice: undefined,
        payMoney: undefined,
        leaveWords: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delOrder(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空删除ids列表
          that.ids = []
        }
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('driving-school/driving-school-order/export', {
            ...that.queryParam
          }, `报名_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleStudentExport () {
      var name='区域报名统计.xlsx'
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        onOk () {
          if (that.queryParam.createBeginTime&&that.queryParam.createEndTime){
            name=`${that.queryParam.createBeginTime.slice(0,10)}至${that.queryParam.createEndTime.slice(0,10)}区域报名统计.xlsx`
          }
          that.download('driving-school/driving-school-order/exportStudent', {
            ...that.queryParam
          }, name)
        },
        onCancel () {}
      })
    },
    handleStudentExportByEmp () {
      var name='员工区域报名统计.xlsx'
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        onOk () {
          if (that.queryParam.createBeginTime&&that.queryParam.createEndTime){
            name=`${that.queryParam.createBeginTime.slice(0,10)}至${that.queryParam.createEndTime.slice(0,10)}员工区域报名统计.xlsx`
          }
          that.download('driving-school/driving-school-order/listStudentExportByEmp', {
            ...that.queryParam
          }, name)
        },
        onCancel () {}
      })
    }
  }
}
</script>
